import { render, staticRenderFns } from "./ReviewList.vue?vue&type=template&id=3b922ac6"
import script from "./ReviewList.vue?vue&type=script&lang=js"
export * from "./ReviewList.vue?vue&type=script&lang=js"
import style0 from "./ReviewList.vue?vue&type=style&index=0&id=3b922ac6&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports